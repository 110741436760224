export default {
  data: {
    unitWidth: "200px",
    headerColor: "bg-pink-200",
    title: "Chat output",
    subclass: "Export / Visualization",
    type: "plot",
    action: "chatout",
    shortdescription: `
    Displays the message back from an agentic system.
        `,
    description: `
Displays the message back from an agentic system
    `,
    numTargets: 1,
    numSources: 0,
    targetLabels: ["Message"],
    sourceLabels: [],
    targetTypes: ["str"],
    sourceTypes: [],
    params: {},
  },
};
