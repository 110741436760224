export default {
  data: {
    unitWidth: "250px",
    headerColor: "bg-blue-200",
    title: "Parse dict to text",
    action: "parsedict",
    type: "rag",
    subclass: "Agentic",
    shortdescription: `
Converts data into plain text.
    `,
    description: `
    Converts data into plain text.
    `,
    numTargets: 1,
    numSources: 1,
    targetLabels: ["Data"],
    sourceLabels: ["Text"],
    targetTypes: ["dict"],
    sourceTypes: ["str"],
    params: {},
    dynamicList: false,
  },
};
