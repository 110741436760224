import React, { useEffect, useState } from "react";
import ToggleSwitch from "./forms/ToggleSwitch";
import { useReactFlow } from "@xyflow/react";
import { handleParamChange } from "../../node-utils/nodeUtils";
import { getModels } from "../../../../services/modelsApi";
import Dropdown from "./forms/Dropdown";
import { PlusIcon } from "@heroicons/react/20/solid"; // XMarkIcon for delete button

export default function NodeInput({ nodeId, params }) {
  const {
    id: paramId,
    label,
    formType,
    min,
    max,
    options: initialOptions,
    value: initialValue,
  } = params;
  const [value, setValue] = useState(initialValue ?? null);
  const [options, setOptions] = useState(initialOptions);
  const { setNodes } = useReactFlow();

  useEffect(() => {
    if (paramId === "model_name") {
      getModels()
        .then((models) => {
          setOptions(models);
        })
        .catch((error) => {
          console.error("Error fetching models:", error);
        });
    }
  }, [paramId]);

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setValue(newValue);
    }
  };

  const handleDropdownChange = (selectedOption) => {
    setValue(selectedOption);
  };

  const handleToggleChange = (newValue) => {
    setValue(newValue);
  };

  const handleIntegerChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
  };

  const handleFloatChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setValue(newValue);
  };

  const handleTextChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    handleParamChange(setNodes, nodeId, paramId, value);
  }, [value, nodeId]);

  const handleLoadFromPromptLibrary = () => {
    console.log("Load from Prompt Library clicked");
    // Add your logic for loading from the prompt library here
  };

  return (
    <div className="flex-1 flex items-center justify-start px-2 w-full">
      {formType === "toggle" ? (
        <ToggleSwitch enabled={value} setEnabled={handleToggleChange} />
      ) : formType === "slider" ? (
        <input
          type="range"
          min={min || 0}
          max={max || 100}
          value={value ?? min ?? 0}
          onChange={handleSliderChange}
          className="w-full h-2 nodrag bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        />
      ) : formType === "dropdown" ? (
        <Dropdown
          options={options || []}
          selected={value ?? options[0] ?? ""}
          setSelected={handleDropdownChange}
        />
      ) : formType === "integer" ? (
        <input
          type="number"
          value={value ?? ""}
          onChange={handleIntegerChange}
          className="appearance-none w-full h-8 px-2 rounded-md bg-gray-50 border border-gray-300 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200 no-spinner"
        />
      ) : formType === "float" ? (
        <input
          type="number"
          step="any"
          value={value ?? ""}
          onChange={handleFloatChange}
          className="appearance-none w-full h-8 px-2 rounded-md bg-gray-50 border border-gray-300 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200 no-spinner"
        />
      ) : formType === "textBox" ? (
        <div className="overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="textBox" className="sr-only">
            Text Input
          </label>
          <textarea
            id="textBox"
            name="textBox"
            value={value ?? ""}
            onChange={handleTextChange}
            placeholder={`Enter ${label.toLowerCase()} here...`}
            rows={4}
            className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
      ) : formType === "prompt" ? (
        <form action="#" className="relative w-full">
          <div className="overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              id="comment"
              name="comment"
              rows={3}
              placeholder="Add your prompt template..."
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              defaultValue={""}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div aria-hidden="true" className="py-2">
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between border-gray-100 py-2 pl-2 pr-2">
            <div className="flex items-center space-x-5"></div>
            <div className="flex-shrink-0">
              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-gray-100 border-gray-300 border-2 px-1 py-1 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-200"
              >
                <PlusIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </form>
      ) : (
        <input
          type="text"
          value={value ?? ""}
          onChange={(e) => setValue(e.target.value)}
          placeholder={`Enter ${label.toLowerCase()}...`}
          className="appearance-none w-full h-8 px-2 rounded-md bg-gray-50 border border-gray-300 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-200 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      )}
    </div>
  );
}
