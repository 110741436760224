export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-green-200",
    title: "CSV uploader",
    type: "input",
    subclass: "Input",
    shortdescription: `
Upload data from a spreadsheet.
    `,
    description: `
Here I should put a description about what a Mulitvariant Sampler is!
    `,
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Data"],
    targetTypes: [],
    sourceTypes: ["samples"],
    params: {},
    action: "csvUploader",
  },
};
