import React, { createContext, useContext, useState, ReactNode } from "react";

// Define a type for the context value
interface InteractionContextType {
  isInteractive: boolean;
  disableInteraction: () => void;
  enableInteraction: () => void;
}

// Create a context with a default value
const InteractionContext = createContext<InteractionContextType | undefined>(
  undefined
);

// Define the props for the provider
interface InteractionProviderProps {
  children: ReactNode; // Allow any React node as children
}

// Provider component to wrap your app or parts of it
export const InteractionProvider: React.FC<InteractionProviderProps> = ({
  children,
}) => {
  const [isInteractive, setIsInteractive] = useState<boolean>(true);

  // Function to disable interaction
  const disableInteraction = () => setIsInteractive(false);

  // Function to enable interaction
  const enableInteraction = () => setIsInteractive(true);

  return (
    <InteractionContext.Provider
      value={{
        isInteractive,
        disableInteraction,
        enableInteraction,
      }}
    >
      {children}
    </InteractionContext.Provider>
  );
};

// Custom hook to use the interaction context
export const useInteraction = (): InteractionContextType => {
  const context = useContext(InteractionContext);

  if (!context) {
    throw new Error(
      "useInteraction must be used within an InteractionProvider"
    );
  }

  return context;
};
