import generateHandles from "./generateHandles";

interface NodeData {
  id: string;
  data: {
    unitWidth: string;
    headerColor: string;
    title: string;
    type: string;
    subclass: string;
    shortdescription: string;
    description: string;
    numTargets: number;
    numSources: number;
    targetLabels: string[];
    sourceLabels: string[];
    targetTypes: string[];
    sourceTypes: string[];
    params: Record<string, any>;
    action: string;
    handles: Array<{
      id: string;
      type: string;
      position: string;
      style: Record<string, number>;
      dataType: string;
      isValidConnection: boolean;
    }>;
    updateNodeData: Record<string, any>;
    status: string;
  };
  type: string;
  position: {
    x: number;
    y: number;
  };
  measured: {
    width: number;
    height: number;
  };
  selected: boolean;
  dragging: boolean;
}

export const checkWorkflowComplete = (nodes: NodeData[]): boolean => {
  // Iterate through all the nodes
  for (const node of nodes) {
    // Check if the node's subclass is "Export / Visualization" and status is "green"
    if (
      node.data.subclass === "Export / Visualization" &&
      node.data.status === "green"
    ) {
      return true; // If found, return true
    }
  }
  return false; // If not found, return false
};

export const isPlot = (nodes: NodeData[]): boolean => {
  return nodes.some(
    (node) => node.data.action === "plotline1D" && node.data.status === "green"
  );
};

export const handleParamChange = async (
  setNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  nodeId: string,
  paramId: string,
  newValue: any
) => {
  setNodes((prevNodes) =>
    prevNodes.map((node) => {
      if (node.id === nodeId) {
        if (Array.isArray(node.data.params)) {
          const updatedParams = node.data.params.map((param: any) => {
            if (param.id === paramId) {
              return { ...param, value: newValue };
            }
            return { ...param };
          });
          return {
            ...node,
            data: {
              ...node.data,
              params: updatedParams,
            },
          };
        } else {
          console.error(
            `node.data.params is not an array for node with id ${nodeId}`
          );
        }
      }
      return { ...node };
    })
  );
};

export const handleDynamicHandlesChange = async (
  setNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  nodeId: string,
  NewDynamicHandles: any
) => {
  setNodes((prevNodes) =>
    prevNodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            dynamicList: NewDynamicHandles,
          },
        };
      } else {
        console.error(`Failed to update dynamic handles for node ${nodeId}`);
      }
      return { ...node };
    })
  );
};

export const addNewNode = async (
  nodes: NodeData[],
  setNodes: any,
  node_type: string,
  edges = [],
  updateNodeData: any,
  onEdit: any
) => {
  try {
    // Dynamically import the configuration file based on the node_type
    const nodeConfig = await import(`../node-configs/${node_type}.js`);

    // Create a new node using the imported configuration

    let id = (nodes.length + 1).toString();

    const handles = generateHandles(
      nodeConfig.default.data.numTargets,
      nodeConfig.default.data.numSources,
      nodeConfig.default.data.targetTypes,
      nodeConfig.default.data.sourceTypes,
      60
    ).handles;

    const newNode = {
      id: id,
      data: {
        ...nodeConfig.default.data,
        handles: handles, // Initialize handles as an empty array
        onEdit,
        id,
      },
      type: "base", // Assuming 'base' type for non-slider nodes
      position: {
        x: Math.random() * 400,
        y: Math.random() * 400,
      },
    };
    // Add the new node to the state
    setNodes((nds: any) => [...nds, newNode]);
  } catch (error) {
    console.error(
      `Error loading node configuration for type: ${node_type}`,
      error
    );
  }
};
