// External imports
import React from "react";
import Grid from "@mui/material/Grid";
// Internal imports
import Plot1D from "./Plot1D";

interface PlotPanelProps {
  width: number;
  currentTabId: string;
}

const PlotPanel: React.FC<PlotPanelProps> = ({ width, currentTabId }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <div>
          <Plot1D width={width} currentTabId={currentTabId} nPlots={3} />
        </div>
      </Grid>
    </Grid>
  );
};

export default PlotPanel;
