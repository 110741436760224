export default {
  data: {
    unitWidth: "400px",
    headerColor: "bg-blue-200",
    title: "Prompt",
    action: "prompt",
    type: "rag",
    subclass: "Agentic",
    shortdescription: `
Construct a prompt for an LLM.
    `,
    description: `
    Retrieve relevant information from a vector store.
    `,
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Message"],
    targetTypes: [],
    sourceTypes: ["dict"],
    params: [
      {
        id: "prompt_message",
        label: "Prompt Message",
        formType: "prompt",
        value: "",
      },
    ],
    dynamicList: [],
  },
};
