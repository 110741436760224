// @ts-nocheck
// Above comment removes typescript typechecking, without it
// the `type` parameter in plotData gives an error for plotly
import React, { useEffect, useState, useMemo } from "react";
import Plot from "react-plotly.js";
import { values } from "./App";
import { getTabName } from "../../utils/DBUtils";
import tritiumOutput from "./plot-data/tritium_output.json";
import quickstartOutput from "./plot-data/quickstart_output.json";

type Plot1DProps = {
  width: number;
  currentTabId: number;
};

type PlotValues = {
  selectedModel: string;
  yLabel: string;
  xLabel: string;
  xVals: number[];
  predMean: number[][];
  predLower1: number[][];
  predUpper1: number[][];
  predLower2: number[][];
  predUpper2: number[][];
};

const Plot1D: FC<Plot1DProps> = ({ width, currentTabId }) => {
  const plotVals: PlotValues = quickstartOutput;

  const [values, setValues] = useState({
    selectedModel: getTabName(currentTabId),
    yLabel: plotVals.y_label,
    xLabel: plotVals.x_label,
    xVals: plotVals.x_vals,
    predMean: plotVals.mean,
    predLower1: plotVals.lower,
    predUpper1: plotVals.upper,
    predLower2: plotVals.lower,
    predUpper2: plotVals.upper,
    predLower2: plotVals.lower.map((row: number[], rowIndex: number) =>
      row.map(
        (x: number, colIndex: number) => x - plotVals.std[rowIndex][colIndex]
      )
    ),
    predUpper2: plotVals.upper.map((row: number[], rowIndex: number) =>
      row.map(
        (x: number, colIndex: number) => x + plotVals.std[rowIndex][colIndex]
      )
    ),
  });

  const colors = [
    "rgb(125, 185, 40)", // Spring green
    "rgb(22, 36, 72)", // DigiLab blue
    "rgb(0, 159, 227)", // Ocean blue
    "rgb(110, 231, 183)", // Tidal blue
    "rgb(182, 182, 175)", // Ash grey
  ];

  const plotData = useMemo(() => {
    const data = [];
    for (let i = 0; i < values.predMean.length; i++) {
      const color = colors[i % colors.length]; // Cycle through colors
      data.push(
        // Solid mean line
        {
          type: "scatter",
          x: values.xVals,
          y: values.predMean[i],
          line: { color: color },
          name: `Prediction ${i + 1}`,
          mode: "lines",
          showlegend: values.predMean.length > 1,
        },
        // 1 Sigma std shaded region
        {
          type: "scatter",
          x: values.xVals.concat(values.xVals.slice().reverse()),
          y: values.predLower1[i].concat(
            values.predUpper1[i].slice().reverse()
          ),
          fill: "toself",
          fillcolor: `rgba(${color.match(/\d+/g)?.join(",")}, 0.4)`,
          line: { color: "transparent" },
          name: `Uncertainty band 1`,
          showlegend: false,
        }
      );
      // 2 Sigma std shaded region (conditionally added)
      if (values.predMean.length === 1) {
        data.push({
          type: "scatter",
          x: values.xVals.concat(values.xVals.slice().reverse()),
          y: values.predLower2[i].concat(
            values.predUpper2[i].slice().reverse()
          ),
          fill: "toself",
          fillcolor: `rgba(${color.match(/\d+/g)?.join(",")}, 0.2)`,
          line: { color: "transparent" },
          name: `Uncertainty band 2`,
          showlegend: false,
        });
      }
    }
    return data;
  }, [values]);

  const plotLayout = useMemo(
    () => ({
      title: values.selectedModel,
      width: width,
      height: width,
      xaxis: { title: values.xLabel || "" },
      yaxis: { title: values.yLabel || "" },
      legend: {
        x: 1,
        xanchor: "right",
        y: 1,
      },
    }),
    [values, width]
  );

  // Return the plotly Plot component with current data and layout
  return (
    <>
      <Plot data={plotData} layout={plotLayout} />
    </>
  );
};

export default Plot1D;
