import React, { useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { headerNavIcons } from "../../../assets/icons/customIcons";
import LoadCanvasModal from "./LoadCanvasModal";
import Header from "../Header";

import { addTab, tabs, selectTab } from "../../../hooks/useTabs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NewCanvasModal({
  open,
  setOpen,
  openLoadCanvasModal,
  addTab,
  selectTab,
  tabs,
}) {
  const handleAddTab = (tabId) => {
    addTab(tabId);
    selectTab(tabs.length);
  };

  const actions = [
    {
      title: "Build a new model",
      href: "#",
      icon: headerNavIcons.model,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
      description: "Create a new model from scratch.",
      onClick: () => {
        handleAddTab("m0");
        setOpen(null);
      },
    },
    {
      title: "Load an existing model",
      href: "#",
      icon: headerNavIcons.model,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      description: "Load and modify an existing model.",
      onClick: () => {
        setOpen(null);
        openLoadCanvasModal("models");
      },
    },
    {
      title: "Build a new workflow",
      href: "#",
      icon: headerNavIcons.workflow,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      description: "Create a new workflow for your project.",
      onClick: () => {
        handleAddTab("w0");
        setOpen(null);
      },
    },
    {
      title: "Load an existing workflow",
      href: "#",
      icon: headerNavIcons.workflow,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
      description: "Load and edit a pre-existing workflow.",
      onClick: () => {
        setOpen(null);
        openLoadCanvasModal("workflows");
      },
    },
    {
      title: "Build a new application",
      href: "#",
      icon: headerNavIcons.app,
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
      description: "Start building a new application.",
      onClick: () => {
        handleAddTab("a0");
        setOpen(null);
      },
    },
    {
      title: "Load an existing application",
      href: "#",
      icon: headerNavIcons.app,
      iconForeground: "text-indigo-700",
      iconBackground: "bg-indigo-50",
      description: "Load and continue working on an application.",
      onClick: () => {
        setOpen(null);
        openLoadCanvasModal("apps");
      },
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-3xl h-[80vh] flex flex-col">
                {/* Close button */}
                <button
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
                  onClick={() => setOpen("null")}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Header */}
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-gray-900">
                    What do you want to build next?
                  </h2>
                </div>

                {/* Action Panel */}
                <div className="p-6 pt-0 grid grid-cols-1 sm:grid-cols-2 gap-4 flex-grow overflow-y-auto">
                  {actions.map((action) => (
                    <div
                      key={action.title}
                      className="relative p-4 bg-white shadow-md rounded-lg cursor-pointer hover:shadow-lg border border-gray-200 text-sm transition-all duration-200 hover:bg-gray-50"
                      onClick={action.onClick}
                    >
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            "inline-flex rounded-lg p-3"
                          )}
                        >
                          {action.icon}
                        </span>
                        <h3 className="ml-3 text-lg font-semibold text-gray-900">
                          <a href={action.href} className="focus:outline-none">
                            {action.title}
                          </a>
                        </h3>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        {action.description}
                      </p>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default NewCanvasModal;
