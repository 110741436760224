import React from "react";
import { Handle, Position } from "@xyflow/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid"; // XMarkIcon for delete button

export default function DynamicList({
  extraInputs,
  setExtraInputs,
  addDynamicHandle, // Passed from parent to add handles
  deleteDynamicHandle, // Passed from parent to delete handles
}) {
  // Function to add a new item with a unique ID
  const addItem = () => {
    const newItem = { id: `dynamic_${extraInputs.length + 1}`, content: "" };
    setExtraInputs([...extraInputs, newItem]);
    // Call the parent method to add a dynamic handle
    addDynamicHandle();
  };

  // Function to delete an item by its ID
  const deleteItem = (id) => {
    setExtraInputs(extraInputs.filter((item) => item.id !== id));
    deleteDynamicHandle(id); // Call the parent method to delete a dynamic handle
  };

  // Function to check if the content contains curly brackets
  const isValidVariable = (text) => {
    const regex = /\{(.*?)\}/g;
    return regex.test(text); // Returns true if curly brackets are present
  };

  const handleInputChange = (e, id) => {
    const value = e.target.value;
    setExtraInputs(
      extraInputs.map((input) =>
        input.id === id ? { ...input, content: value } : input
      )
    );
  };

  return (
    <div className="w-full">
      <ul role="list" className="space-y-1">
        {/* Display extraInputs list with delete button */}

        {/* Add prompt template input */}
        <li className="overflow-hidden rounded-md bg-gray-50 py-2">
          <div className="items-start space-x-4">
            <div className="min-w-0"></div>
          </div>
        </li>
        {extraInputs.length > 0 && (
          <ul>
            {extraInputs.map((item, i) => (
              <li
                key={item.id}
                className="overflow-hidden rounded-md bg-gray-100 px-2 py-2 shadow relative"
              >
                <div className="flex items-center">
                  {/* Textarea for dynamic input */}
                  <input
                    type="text"
                    value={item.content}
                    onChange={(e) => handleInputChange(e, item.id)}
                    placeholder={`Enter Variable`}
                    className={`w-full border border-gray-300 rounded-md p-2 ${
                      isValidVariable(item.content)
                        ? "bg-green-50 text-green-700 ring-green-600/20"
                        : "bg-yellow-50 text-yellow-800 ring-yellow-600/20"
                    }`}
                  />

                  {/* Delete button in the middle right */}
                  <button
                    type="button"
                    className="ml-2 flex items-center justify-center h-6 w-6 rounded-full text-white bg-gray-500 hover:bg-red-500 focus:outline-none"
                    onClick={() => deleteItem(item.id)}
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* Button to add a new list item */}
        <li className="py-2">
          <button
            type="button"
            className="relative w-full flex justify-center items-center rounded-full border-2 border-dashed border-gray-300 p-2 hover:border-gray-400 focus:ring-offset-2"
            onClick={addItem}
          >
            <PlusIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
          </button>
        </li>
      </ul>
    </div>
  );
}
