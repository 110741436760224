export function jsonToCSV(
  jsonData: Record<string, Record<number, any>>,
  includeIndex: boolean = false
): string {
  const columns = Object.keys(jsonData);
  const rowCount = Object.values(jsonData)[0]; // Assuming all columns have the same number of rows
  const indexes = Object.keys(rowCount).map(Number);

  // Create CSV Header
  const headers = includeIndex ? ["Index", ...columns] : columns;
  let csv = headers.join(",") + "\n";

  // Create CSV Rows
  for (const index of indexes) {
    const rowData = columns.map((col) => jsonData[col][index]);
    const row = includeIndex ? [index, ...rowData] : rowData;
    csv += row.join(",") + "\n";
  }

  return csv;
}
