import React, { useState } from "react";
import { WorkflowsApi, WorkflowParams } from "./api";
import { jsonToCSV } from "../utils/dataUtils";
import { loadingIcon } from "../assets/icons/customIcons.js";

// Instantiate class
const apiInstance = new WorkflowsApi();

// Args required for the postWorkflow method
const workflowId = "quickstart-wf";
const xUser = process.env.REACT_APP_USER || "";
const xApiKey = process.env.REACT_APP_API_KEY || "";
const workflowParams: WorkflowParams = {
  description: "Quickstart workflow",
  context: "MWE",
  nodes: [
    { id: "node1", type: "linspace" },
    { id: "node2", type: "tl_model" },
    { id: "node3", type: "predict" },
    { id: "node4", type: "csv_download" },
  ],
  edges: [
    { source: "node1", target: "node3" },
    { source: "node2", target: "node3" },
    { source: "node3", target: "node4" },
  ],
  options: {
    option1: true,
    option2: false,
  },
};

interface ButtonGetWorkflowProps {
  className?: string;
}

interface WorkflowResults {
  [key: string]: {
    [key: string]: any;
  };
}

// Component
const ButtonGetWorkflow: React.FC<ButtonGetWorkflowProps> = ({ className }) => {
  const [loading, setLoading] = useState(false);

  const handleGetWorkflowStatus = async (): Promise<string> => {
    try {
      const response = await apiInstance.getWorkflowStatus(
        workflowId,
        xUser,
        xApiKey
      );
      // In the future, instead of console logging the response, we will use it to plot/download/chat, etc.
      return response.data.status;
    } catch (error) {
      console.error("Error calling GetWorkFlow API:", error);
      throw error;
    }
  };

  const downloadCSV = (csvData: string): void => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "workflow_results.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleGetWorkflowResults = async () => {
    setLoading(true);
    const pollingInterval = 5000; // 5 seconds
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const postResponse = await apiInstance.postWorkflow(
      workflowId,
      xUser,
      xApiKey,
      workflowParams
    );

    // Polling loop
    while (true) {
      const status = await handleGetWorkflowStatus();
      if (status === "success") {
        break;
      }
      if (status === "failed") {
        throw new Error("Process failed");
      }
      await delay(pollingInterval);
    }

    try {
      const response = await apiInstance.getWorkflowResults(
        workflowId,
        xUser,
        xApiKey
      );
      // In the future, instead of console logging the response, we will use it to plot/download/chat, etc.
      console.log("Get Workflow Results", response.data);

      const results: WorkflowResults = response.data.results as WorkflowResults;
      const csvData = jsonToCSV(results);
      console.log("Get CSV", csvData);
      downloadCSV(csvData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button className={className} onClick={handleGetWorkflowResults}>
      {loading ? loadingIcon : "GO"}
    </button>
  );
};

export default ButtonGetWorkflow;
