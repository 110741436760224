import { useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function Dropdown({ options, selected, setSelected }) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative nowheel w-full">
        {/* Dropdown button */}
        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm">
          <span className="block truncate w-full">
            {selected || "Select an option"}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        {/* Options */}
        <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.length === 0 ? (
            <ListboxOption
              disabled
              className="relative cursor-default select-none px-4 py-2 text-gray-400"
            >
              No options available
            </ListboxOption>
          ) : (
            options.map((option) => (
              <ListboxOption
                key={option}
                value={option}
                className={({ active, selected }) =>
                  `relative cursor-default select-none py-2 pr-10 pl-4 ${
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  } ${selected ? "font-semibold" : "font-normal"}`
                }
              >
                <span className="block truncate">{option}</span>
                {option === selected && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <CheckIcon
                      className="h-5 w-5 text-indigo-600"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </ListboxOption>
            ))
          )}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
