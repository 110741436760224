import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";

function NodeDropdownMenu({ menuItems }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="inline-flex w-full justify-center rounded-md text-gray-500 hover:text-gray-700">
        <svg
          className="h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6.75a.75.75 0 100 1.5.75.75 0 000-1.5zm0 4.5a.75.75 0 100 1.5.75.75 0 000-1.5z"
          />
        </svg>
      </MenuButton>

      <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {menuItems.map((item, index) => (
          <div className="py-1" key={index}>
            <MenuItem>
              {({ active }) => (
                <a
                  href="#"
                  onClick={item.onClick}
                  className={`group flex items-center px-4 py-2 text-sm text-gray-700 ${
                    active ? "bg-gray-100 text-gray-900" : ""
                  }`}
                >
                  {item.icon && (
                    <item.icon
                      aria-hidden="true"
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    />
                  )}
                  {item.label}
                </a>
              )}
            </MenuItem>
          </div>
        ))}
      </MenuItems>
    </Menu>
  );
}

export default NodeDropdownMenu;
