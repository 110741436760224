const ProgressBar = ({ currentStep }: { currentStep: number }) => {
  // Define the steps in an array
  const steps = [
    "Model Information",
    "Upload Dataset",
    "Define ML Model",
    "Train",
    "Validate & Register",
  ];

  // Calculate the progress percentage based on the current step
  const progressPercentage = (currentStep / steps.length) * 100;

  return (
    <div aria-hidden="true" className="mt-6">
      {/* Progress bar */}
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          style={{ width: `${progressPercentage}%` }}
          className="h-2 rounded-full bg-emerald-500"
        />
      </div>

      {/* Step labels */}
      <div className="mt-6 flex justify-between text-sm font-medium text-gray-600">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`text-center ${currentStep > index ? "text-emerald-600" : ""}`}
          >
            {step}
          </div>
        ))}
      </div>
    </div>
  );
};

// Correctly export the component as default
export default ProgressBar;
