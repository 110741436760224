import React from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "@xyflow/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"; // Import the warning icon

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data = {}, // Receive the edge data, including isValid and errorMessage
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  // Function to trigger the notification on click
  const onEdgeClick = () => {
    if (!data.isValid && data.errorMessage) {
      console.log("Notification triggered:", data.errorMessage); // Debugging log
    }
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          {/* Orange circle with warning sign */}
          <div
            className="w-5 h-5 bg-yellow-500 rounded-full flex items-center justify-center cursor-pointer"
            onClick={onEdgeClick}
          >
            <ExclamationTriangleIcon className="h-4 w-4 text-white" />
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
