/* tslint:disable */
/* eslint-disable */
/**
 * twinLab API
 *   The **Machine Learning API Platform** built for Engineers 🚀  ![Alt text](https://i.ibb.co/CsRN6G7/gp-twinlab.png)  ### **What is twinLab?**:  twinLab is a probablistic machine learning platform designed to bring explainable and robust statistical techniques into the hands of industry domain experts without the need to learn a new area.  ### **Use twinLab to**: - **Accelerate** simulations and experiments with explainable parameter optimisation and exploration - **Quantify uncertainty** in models, experiments and systems to understand risk to operational and safety objectives - **Gain a digital asset** in the form of a real-time emulator for active interogation or digital twinning
 *
 * The version of the OpenAPI document: 3.4.0
 * Contact: twinLab digiLab <twinlab@digilab.co.uk>
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

export const BASE_PATH = "https://twinlab.digilab.co.uk/v3".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: RawAxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath ?? basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  constructor(
    public field: string,
    msg?: string
  ) {
    super(msg);
    this.name = "RequiredError";
  }
}

interface ServerMap {
  [key: string]: {
    url: string;
    description: string;
  }[];
}

/**
 *
 * @export
 */
export const operationServerMap: ServerMap = {};
