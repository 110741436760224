import React, { useState } from "react";
import { Handle, Position } from "@xyflow/react";
import NodeInput from "./node-components/NodeInput";

export default function VariableNode({ data }) {
  const nodeId = data?.id;
  const params = data?.params;

  return (
    <div
      className="rounded-lg bg-yellow-100 border border-gray-300 shadow-sm p-4 flex"
      style={{ width: data?.unitWidth }}
    >
      {/* Left Section (Label) */}
      <div
        className="flex items-center justify-start pr-4"
        style={{ display: "inline-block" }}
      >
        <span className="text-gray-700 font-semibold">
          {data?.label || "Variable"}
        </span>
      </div>
      {params &&
        Array.isArray(params) &&
        params.length > 0 &&
        Object.keys(params).map((paramId) => (
          <NodeInput
            nodeId={nodeId}
            params={params[paramId]}
            key={`param-${paramId}`}
          />
        ))}

      {/* Handle on the right, positioned exactly at the boundary */}
      <Handle
        type="source"
        position={Position.Right}
        id="source_1"
        className="rounded-full w-2 h-2"
        style={{
          right: "-3px", // Move it slightly outside the boundary
          top: "50%",
          transform: "translateY(-50%)", // Vertically center it
        }}
      />
    </div>
  );
}
