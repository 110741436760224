import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  PlusIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import {
  getOrganizationFromUser,
  getOrganizationMembers,
  getProjectMembers,
  getUsersDetails,
} from "../../../utils/DBUtils";

function ManageProjectSharing({ open, setOpen, currentUser, currentProject }) {
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState([]);

  const currentOrganization = getOrganizationFromUser(currentUser);
  const organizationMembers = getOrganizationMembers(currentOrganization);
  const projectMembers = getProjectMembers(currentProject);
  const recommendedMembers = organizationMembers.filter(
    (id) => !new Set(projectMembers).has(id)
  );

  const currentTeam = getUsersDetails(projectMembers, currentProject);
  const recommendedTeam = getUsersDetails(recommendedMembers, currentProject);

  const [showDownArrow, setShowDownArrow] = useState(false);
  const currentTeamRef = useRef(null);

  useEffect(() => {
    const element = currentTeamRef.current;
    if (element) {
      const handleScroll = () => {
        if (element.scrollTop + element.clientHeight < element.scrollHeight) {
          setShowDownArrow(true);
        } else {
          setShowDownArrow(false);
        }
      };
      handleScroll(); // Check initially

      element.addEventListener("scroll", handleScroll);
      return () => element.removeEventListener("scroll", handleScroll);
    }
  }, [currentTeamRef]);

  const validateEmail = (email) => {
    // Simple email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const addEmail = (email) => {
    if (email && !emails.find((e) => e.email === email)) {
      const isValid = validateEmail(email);
      setEmails([...emails, { email, isValid }]);
    }
    setEmailInput("");
  };

  const handleAddEmail = (email) => {
    addEmail(email);
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((e) => e.email !== emailToRemove));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const emailsToAdd = emailInput.split(",").map((email) => email.trim());
      emailsToAdd.forEach((email) => {
        if (email) {
          addEmail(email);
        }
      });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.includes(",")) {
      const emailsToAdd = value.split(",").map((email) => email.trim());
      emailsToAdd.slice(0, -1).forEach((email) => {
        if (email) {
          addEmail(email);
        }
      });
      setEmailInput(emailsToAdd[emailsToAdd.length - 1]);
    } else {
      setEmailInput(value);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(null)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* Overlay */}
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Modal Content */}
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-4xl h-[85vh] flex flex-col">
                {/* Close button */}
                <button
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                  onClick={() => setOpen(null)}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Header */}
                <div className="p-6">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-900">
                    Share this project
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    Invite team members to collaborate on this project.
                  </p>
                </div>

                {/* Content area */}
                <div className="p-6 pt-0 flex-grow flex flex-col min-h-0">
                  {/* Invite Form */}
                  <form
                    className="sm:flex sm:items-center"
                    onSubmit={(e) => {
                      e.preventDefault();
                      // Handle form submission logic here
                    }}
                  >
                    <label htmlFor="emails" className="sr-only">
                      Email addresses
                    </label>
                    <div className="grid grid-cols-1 sm:flex-auto">
                      <div
                        className="relative col-start-1 row-start-1 flex items-center gap-2 py-1.5 px-2 border-0 bg-transparent sm:text-sm sm:leading-6 overflow-x-auto"
                        onClick={() =>
                          document.getElementById("emails").focus()
                        }
                      >
                        <div className="flex items-center gap-2 flex-nowrap">
                          {emails.map((emailObj) => (
                            <span
                              key={emailObj.email}
                              className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                                emailObj.isValid
                                  ? "bg-green-50 text-green-700 ring-green-600/20"
                                  : "bg-yellow-50 text-yellow-800 ring-yellow-600/20"
                              }`}
                            >
                              {emailObj.email}
                              <button
                                type="button"
                                className="ml-1 text-gray-700 hover:text-gray-900 focus:outline-none"
                                onClick={() => removeEmail(emailObj.email)}
                              >
                                <XMarkIcon
                                  className="h-3 w-3"
                                  aria-hidden="true"
                                />
                              </button>
                            </span>
                          ))}
                          <input
                            id="emails"
                            name="emails"
                            type="text"
                            placeholder="Enter an email"
                            value={emailInput}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            className="flex-1 min-w-[150px] border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div
                        aria-hidden="true"
                        className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus-within:ring-2 peer-focus-within:ring-indigo-600"
                      />
                      <div className="col-start-2 row-start-1 flex items-center">
                        <span
                          aria-hidden="true"
                          className="h-4 w-px flex-none bg-gray-200"
                        />
                        <label htmlFor="role" className="sr-only">
                          Role
                        </label>
                        <select
                          id="role"
                          name="role"
                          className="rounded-md border-0 bg-transparent py-1.5 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option>Can edit</option>
                          <option>Can view</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                      <button
                        type="submit"
                        className="block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Send invite
                      </button>
                    </div>
                  </form>

                  {/* Scrollable area */}
                  <div className="mt-6 flex-grow flex flex-col min-h-0">
                    {/* Recommended Team Members */}
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        Recommended team members
                      </h3>
                      <ul
                        role="list"
                        className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2"
                      >
                        {recommendedTeam.map((person) => (
                          <li key={person.email}>
                            <button
                              type="button"
                              className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => handleAddEmail(person.email)}
                            >
                              <span className="flex min-w-0 flex-1 items-center space-x-3">
                                <span className="block flex-shrink-0">
                                  <img
                                    alt={person.name}
                                    src={person.imageUrl}
                                    className="h-10 w-10 rounded-full"
                                  />
                                </span>
                                <span className="block min-w-0 flex-1">
                                  <span className="block truncate text-sm font-medium text-gray-900">
                                    {person.name}
                                  </span>
                                  <span className="block truncate text-sm font-medium text-gray-500">
                                    {person.title}
                                  </span>
                                </span>
                              </span>
                              <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                                <PlusIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                />
                              </span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Current Team Members */}
                    <div className="mt-6 flex-grow flex flex-col min-h-0 relative">
                      <h3 className="text-xl font-semibold text-gray-900">
                        Current team members ({currentTeam.length} Members)
                      </h3>
                      <div
                        className="mt-4 flex-grow overflow-y-auto"
                        ref={currentTeamRef}
                      >
                        {/* Only this section scrolls */}
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {currentTeam.map((person) => (
                                  <tr key={person.email}>
                                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                      <div className="flex items-center">
                                        <div className="h-11 w-11 flex-shrink-0">
                                          <img
                                            alt={person.name}
                                            src={person.imageUrl}
                                            className="h-11 w-11 rounded-full"
                                          />
                                        </div>
                                        <div className="ml-4">
                                          <div className="font-medium text-gray-900">
                                            {person.name}
                                          </div>
                                          <div className="mt-1 text-gray-500">
                                            {person.email}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                      <div className="text-gray-900">
                                        {person.title}
                                      </div>
                                      <div className="mt-1 text-gray-500">
                                        {person.department}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {person.status}
                                      </span>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                      {person.role}
                                    </td>
                                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                      <a
                                        href="#"
                                        className="text-indigo-600 hover:text-indigo-900"
                                      >
                                        Edit
                                        <span className="sr-only">
                                          , {person.name}
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* Down Arrow Indicator */}
                      {showDownArrow && (
                        <div className="absolute inset-x-0 bottom-0 flex justify-center pointer-events-none">
                          <div className="bg-white px-2 py-1">
                            <ChevronDownIcon
                              className="h-6 w-6 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* End of content area */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ManageProjectSharing;
