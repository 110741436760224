import React from "react";
import { useReactFlow } from "@xyflow/react"; // Import the useReactFlow hook
import { useInteraction } from "../../contexts/InteractionProvider";
import PropTypes from "prop-types"; // Import PropTypes
import {
  MagnifyingGlassPlusIcon,
  MagnifyingGlassMinusIcon,
  ArrowsPointingInIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/24/outline";

const Sidebar = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const { isInteractive, disableInteraction, enableInteraction } =
    useInteraction();

  const actions = [
    {
      id: "zoomIn",
      icon: <MagnifyingGlassPlusIcon className="h-6 w-6 text-gray-600" />,
      action: zoomIn,
      name: "Zoom in",
    },
    {
      id: "zoomOut",
      icon: <MagnifyingGlassMinusIcon className="h-6 w-6 text-gray-600" />,
      action: zoomOut,
      name: "Zoom out",
    },
    {
      id: "fitView",
      icon: <ArrowsPointingInIcon className="h-6 w-6 text-gray-600" />,
      action: () => fitView(),
      name: "Fit view",
    },
    {
      id: "undo",
      icon: <ArrowUturnLeftIcon className="h-6 w-6 text-gray-600" />,
      action: () => alert("coming soon..."),
      name: "Undo",
    },
    {
      id: "redo",
      icon: <ArrowUturnRightIcon className="h-6 w-6 text-gray-600" />,
      action: () => alert("coming soon..."),
      name: "Redo",
    },
    {
      id: "lock",
      icon: isInteractive ? (
        <LockOpenIcon className="h-6 w-6 text-gray-600" />
      ) : (
        <LockClosedIcon className="h-6 w-6 text-gray-600" />
      ),
      action: () => {
        isInteractive ? disableInteraction() : enableInteraction();
      },
      name: "Lock Nodes",
    },
  ];

  return (
    <div className="flex">
      {/* Sidebar */}
      <div className="w-16 bg-white border border-gray-400 rounded-lg p-2 flex flex-col items-center space-y-1">
        {actions.map((action) => (
          <button
            key={action.id}
            className="flex items-center justify-center w-full p-2 hover:bg-gray-100 rounded"
            onClick={action.action} // Call the action function
          >
            {action.icon}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
