export default {
  data: {
    unitWidth: "400px",
    headerColor: "bg-green-200",
    title: "Chat input",
    subclass: "Input",
    type: "input",
    action: "chatinput",
    shortdescription: `
    Get chat inputs from user conversation.
        `,
    description: `
This is a start node of an agentic system, driving by user input in a chat application.
    `,
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Message"],
    targetTypes: [],
    sourceTypes: ["str"],
    params: [
      {
        id: "chat_input",
        label: "Chat input",
        formType: "textBox",
        value: null,
      },
    ],
  },
};
