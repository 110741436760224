export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-blue-200",
    title: "Linspace",
    type: "math",
    subclass: "Mathematical Tool",
    action: "linspace",
    shortdescription: `
  Generate evenly spaced values over a specified interval.
      `,
    description: `
  Given a start value, an end value, and the number of points, generate an array of evenly spaced values over that interval.
      `,
    // numTargets: 3,
    numTargets: 0,
    numSources: 1,
    // targetLabels: ["Start Value", "End Value", "Number of Points"],
    targetLabels: [],
    sourceLabels: ["Values"],
    // targetTypes: ["number", "number", "number"],
    targetTypes: [],
    sourceTypes: ["samples"],
    params: [
      {
        id: "min_num",
        label: "Min value",
        formType: "float",
        value: null,
      },
      {
        id: "max_num",
        label: "Max value",
        formType: "float",
        value: null,
      },
      {
        id: "num_points",
        label: "Length",
        formType: "slider",
        min: 1,
        max: 100,
        value: null,
      },
    ],
  },
};
