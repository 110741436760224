import {
  EmulatorsApi,
  BodyPostEmulator,
  EmulatorParams,
  TrainingParams,
} from "./api";

const apiInstance = new EmulatorsApi();

const emulatorId = "quickstart";
const xUser = process.env.REACT_APP_USER || "";
const xApiKey = process.env.REACT_APP_API_KEY || "";
const emulatorParams: EmulatorParams = {
  inputs: ["Pack price [GBP]", "Number of biscuits per pack"],
  outputs: ["Number of packs sold", "Profit [GBP]"],
};
const trainingParams: TrainingParams = {
  dataset_id: "biscuits",
};
const bodyEmulator: BodyPostEmulator = {
  emulator_params: emulatorParams,
  training_params: trainingParams,
};

export async function getModels() {
  // Call the method from the instance class sending the parameters defined above
  try {
    const response = await apiInstance.getEmulators(xUser, xApiKey);
    const models = response.data.emulators;
    return models;
  } catch (error) {
    console.error("Error calling getEmulators API:", error);
    throw error; // Re-throw the error after logging it
  }
}

export async function postModel(
  emulatorId: string,
  bodyEmulator: BodyPostEmulator
) {
  // Call the method from the instance class sending the parameters defined above
  try {
    const response = await apiInstance.postEmulator(
      emulatorId,
      xUser,
      xApiKey,
      bodyEmulator
    );
    return response.data;
  } catch (error) {
    console.error("Error calling getEmulators API:", error);
    throw error; // Re-throw the error after logging it
  }
}

export async function getModelParameters(emulatorId: string) {
  // Call the method from the instance class sending the parameters defined above
  let parameters;
  apiInstance
    .getEmulatorParameters(emulatorId, xUser, xApiKey)
    // Manage the response from the API
    .then((response) => {
      parameters = response.data;
    })
    .catch((error) => {
      console.error("Error calling PostWorkFlow API:", error);
    });
  return parameters;
}
