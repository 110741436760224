import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  CheckCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import loadNodeConfigs from "../nodes/node-utils/loadNodeConfigs";
import { addNewNode } from "../nodes/node-utils/nodeUtils"; // Import the addNewNode function

const nodeTypes = {
  var: { longname: "Variables", bg: "yellow", hoverBg: "bg-yellow-100" },
  input: { longname: "Inputs", bg: "green", hoverBg: "bg-green-100" },
  rag: { longname: "Retrieval agents", bg: "teal", hoverBg: "bg-teal-100" },
  math: {
    longname: "Math/Stats function",
    bg: "blue",
    hoverBg: "bg-blue-100",
  },
  ml: {
    longname: "Machine learning models",
    bg: "indigo",
    hoverBg: "bg-indigo-100",
  },
  router: { longname: "Routers", bg: "red", hoverBg: "bg-red-100" },
  summary: {
    longname: "Summary agent",
    bg: "purple",
    hoverBg: "bg-purple-100",
  },
  output: { longname: "Outputs", bg: "orange", hoverBg: "bg-orange-100" },
  plot: { longname: "Plotting/Export", bg: "pink", hoverBg: "bg-pink-100" },
};

const customTemplates = ["digiLab nodes", "My nodes"];
const nodeCards = loadNodeConfigs();

// Classname utility
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EditNodeModal({ nodes, setNodes, preSelectedType, open, setOpen }) {
  const [selectedNode, setSelectedNode] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeType, setActiveType] = useState(preSelectedType);

  // Filter node cards based on search query and selected node type
  const filteredNodeCards = nodeCards.filter(
    (node) =>
      (activeType === "all" ||
        node.type === activeType ||
        activeType === "popular") &&
      (node.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // Function to handle adding node to the canvas
  const handleAddNode = async () => {
    if (selectedNode) {
      await addNewNode(
        nodes,
        setNodes,
        selectedNode.action,
        [],
        selectedNode.data
      );
      setOpen(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* Overlay */}
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-hidden">
          <div className="flex min-h-full items-center justify-center text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Modal with dynamic search and close button */}
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-5xl h-[85vh] flex flex-col">
                {/* Close button */}
                <button
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                  onClick={() => setOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Header */}
                <div className="p-6 border-b border-gray-200">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-900">
                    Add a node to your canvas
                  </h2>
                  <div className="relative mt-4">
                    <input
                      type="text"
                      className="w-full p-2 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      placeholder="Search nodes..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <MagnifyingGlassIcon
                      className="absolute left-3 top-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>

                <div className="flex flex-1 overflow-hidden">
                  {/* Sidebar */}
                  <div className="w-64 bg-gray-50 h-full overflow-y-auto p-4">
                    {/* All Nodes and Popular */}
                    <nav className="space-y-1">
                      <a
                        href="#"
                        onClick={() => setActiveType("all")}
                        className={classNames(
                          "block px-3 py-2 rounded-md text-sm font-medium",
                          activeType === "all"
                            ? "bg-indigo-100 text-indigo-700"
                            : "text-gray-700 hover:bg-gray-100"
                        )}
                      >
                        All nodes
                      </a>
                      <a
                        href="#"
                        onClick={() => setActiveType("popular")}
                        className={classNames(
                          "block px-3 py-2 rounded-md text-sm font-medium",
                          activeType === "popular"
                            ? "bg-indigo-100 text-indigo-700"
                            : "text-gray-700 hover:bg-gray-100"
                        )}
                      >
                        Popular nodes
                      </a>
                      <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wide mt-4">
                        By Node Type
                      </h3>
                      {Object.keys(nodeTypes).map((nodeType) => (
                        <a
                          key={nodeType}
                          href="#"
                          onClick={() => setActiveType(nodeType)}
                          className={classNames(
                            "block px-3 py-2 rounded-md text-sm font-medium",
                            activeType === nodeType
                              ? `bg-${nodeTypes[nodeType].bg}-100 text-${nodeTypes[nodeType].bg}-700`
                              : "text-gray-700 hover:bg-gray-100"
                          )}
                        >
                          {nodeTypes[nodeType].longname}
                        </a>
                      ))}
                      <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wide mt-4">
                        Custom Templates
                      </h3>
                      {customTemplates.map((template) => (
                        <a
                          key={template}
                          href="#"
                          className="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-100"
                        >
                          {template}
                        </a>
                      ))}
                    </nav>
                  </div>

                  {/* Main content - Node Cards */}
                  <div className="flex-1 p-6 flex flex-col">
                    <div className="flex-1 overflow-y-auto">
                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                        {filteredNodeCards.length > 0 ? (
                          filteredNodeCards.map((node) => {
                            const nodeType = nodeTypes[node.type];

                            return (
                              <div
                                key={node.id}
                                className="relative p-3 bg-gray-50 shadow-md rounded-lg cursor-pointer hover:shadow-lg border border-gray-200 text-sm"
                                onClick={() => setSelectedNode(node)}
                                style={{ height: "120px" }} // Reduced height
                              >
                                <h3 className="text-base font-medium">
                                  {node.title}
                                </h3>
                                <p className="mt-1 text-xs text-gray-500 line-clamp-3">
                                  {node.description}
                                </p>

                                {/* Positioning the tag at the bottom left */}
                                <div className="absolute bottom-2 left-2">
                                  <span
                                    className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium bg-${nodeType.bg}-100 text-${nodeType.bg}-700`}
                                  >
                                    {nodeType.longname}
                                  </span>
                                </div>

                                {selectedNode?.id === node.id && (
                                  <CheckCircleIcon className="absolute top-2 right-2 h-5 w-5 text-indigo-600" />
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <h3 className="text-2xl font-medium">
                            No node found.
                          </h3>
                        )}
                      </div>
                    </div>

                    {/* Buttons Section */}
                    <div className="mt-4 flex-shrink-0 flex justify-end space-x-4">
                      <button
                        type="button"
                        className={classNames(
                          "inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm",
                          selectedNode
                            ? "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600"
                            : "bg-gray-300 text-gray-500 cursor-not-allowed"
                        )}
                        disabled={!selectedNode}
                        onClick={() => alert("More Information Clicked")}
                      >
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="-ml-0.5 h-5 w-5"
                        />
                        More information
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          "inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm",
                          selectedNode
                            ? "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600"
                            : "bg-gray-300 text-gray-500 cursor-not-allowed"
                        )}
                        disabled={!selectedNode}
                        onClick={handleAddNode}
                      >
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="-ml-0.5 h-5 w-5"
                        />
                        Add node
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default EditNodeModal;
