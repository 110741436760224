"use client";

import React, { useState } from "react";
import { twinLabModel } from "../interfaces/twinLabModelInterface";

import ProgressBar from "../components/model/ProgressBar";

import {
  Field,
  Switch,
  Label,
  Description,
  Radio,
  RadioGroup,
} from "@headlessui/react";
import { PlusIcon, ArrowPathIcon } from "@heroicons/react/20/solid";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import {
  CalendarIcon,
  PaperClipIcon,
  TagIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";

const settings = [
  {
    name: "Access for your organisation.",
    description:
      "This model will be available to anyone within your organisation.",
  },
  {
    name: "Private to Project Members",
    description: "Only members of the project can view/copy this model.",
  },
  {
    name: "Private to you",
    description: "You are the only one able to access this model.",
  },
];

const people = [
  { id: 1, name: "Wade Cooper" },
  { id: 2, name: "Arlene Mccoy" },
  { id: 3, name: "Devon Webb" },
  { id: 4, name: "Tom Cook" },
  { id: 5, name: "Tanya Fox" },
  { id: 6, name: "Hellen Schmidt" },
  { id: 7, name: "Caroline Schultz" },
  { id: 8, name: "Mason Heaney" },
  { id: 9, name: "Claudie Smitham" },
  { id: 10, name: "Emil Schaefer" },
];

const assignees = [
  { name: "Unassigned", value: null },
  {
    name: "Wade Cooper",
    value: "wade-cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

const labels = [
  { name: "Unlabelled", value: null },
  { name: "Engineering", value: "engineering" },
  // More items...
];
const dueDates = [
  { name: "No due date", value: null },
  { name: "Today", value: "today" },
  // More items...
];

const defaultModel: twinLabModel = {
  modelName: null,
  modelDescription: null,
  owner_id: "tim_dodwell",
  dataset_id: "",
  emulator_params: {
    inputs: [""],
    outputs: [""],
    estimator: "gaussian_process_regression",
    estimator_params: {
      detrend: true,
      estimator_type: "single_task_gp",
    },
    fidelity: "",
    class_column: "",
    warp_inputs: false,
    decompose_inputs: true,
    input_explained_variance: 0.95,
    input_retained_dimensions: 0,
    decompose_outputs: false,
    output_explained_variance: 0.95,
    output_retained_dimensions: 0,
  },
  training_params: {
    dataset_id: "",
    dataset_std_id: "",
    train_test_ratio: 0.8,
    model_selection: true,
    model_selection_kwargs: {
      evaluation_metric: "MSLL",
      val_ratio: 0.2,
      base_kernels: "restricted",
      depth: 1,
      beam: 2,
    },
    shuffle: true,
    seed: 42,
  },
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ModelForm() {
  const [formData, setFormData] = useState<twinLabModel>(defaultModel);
  const [selected, setSelected] = useState(settings[0]);

  const [selectedDataset, setSelectedDataset] = useState(people[3]);

  const [assigned, setAssigned] = useState(assignees[0]);
  const [labelled, setLabelled] = useState(labels[0]);
  const [dated, setDated] = useState(dueDates[0]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData: twinLabModel) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNestedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    category: keyof twinLabModel
  ) => {
    const { name, value } = e.target;
    setFormData((prevData: twinLabModel) => ({
      ...prevData,
      [category]: {
        ...(prevData[category] as object), // Spread the existing category data
        [name]: value, // Update the specific field inside the category
      },
    }));
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value); // parse to float to keep it as a number
    setFormData((prev) => ({
      ...prev,
      training_params: {
        ...prev.training_params,
        train_test_ratio: value,
      },
    }));
  };

  const handleSwitchChange = (
    checked: boolean,
    category: keyof twinLabModel,
    field: string
  ) => {
    setFormData((prevData: twinLabModel) => ({
      ...prevData,
      [category]: {
        ...(prevData[category] as object),
        [field]: checked, // Update the boolean field (e.g., `decompose_inputs`)
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  return (
    <div className="p-4 mx-auto">
      <ProgressBar currentStep={2} />
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <div>
            <p className="mt-4 text-m text-gray-500">
              Fill in the details to create or update your machine learning
              model.
            </p>
          </div>

          <div>
            <ul role="list" className="divide-y divide-gray-200">
              <Field as="li" className="flex justify-between py-4">
                <div className="flex flex-col mr-4">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Model Name
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Provide a unique name for your model.
                  </Description>
                </div>
                <div className="flex justify-right">
                  <div className="w-full">
                    <input
                      value={formData.modelName || ""} // Provide empty string fallback if null
                      id="modelName"
                      name="modelName"
                      type="text"
                      onChange={handleInputChange}
                      className={`block w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-teal--500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500`}
                    />
                  </div>
                </div>
              </Field>

              <Field as="li" className="flex justify-between py-4">
                <div className="flex flex-col mr-4">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Model Description
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Provide some context about what your model does.
                  </Description>
                </div>
                <div className="flex justify-right" style={{ width: "65%" }}>
                  <div className="w-full">
                    <textarea
                      id="description"
                      className="block h-48 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-teal-500 focus:border-teal-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                      placeholder="Write your thoughts here..."
                    ></textarea>
                  </div>
                </div>
              </Field>

              <Field as="li" className="flex justify-between py-4">
                <div className="flex flex-col mr-4">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Sharing / Privacy
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Determine how you model will be shared with your
                    organisation.
                  </Description>
                </div>
                <div className="flex justify-right" style={{ width: "65%" }}>
                  <div className="w-full">
                    <RadioGroup
                      value={selected}
                      onChange={setSelected}
                      className="isolate mt-2 -space-y-px rounded-md bg-white shadow-sm"
                    >
                      {settings.map((setting, settingIdx) => (
                        <Radio
                          key={setting.name}
                          value={setting}
                          aria-label={setting.name}
                          aria-description={setting.description}
                          className={classNames(
                            settingIdx === 0
                              ? "rounded-tl-md rounded-tr-md"
                              : "",
                            settingIdx === settings.length - 1
                              ? "rounded-bl-md rounded-br-md"
                              : "",
                            "group relative flex cursor-pointer border border-gray-200 p-4 focus:outline-none data-[checked]:z-10 data-[checked]:border-teal-200 data-[checked]:bg-teal-50"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className="mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white group-data-[checked]:border-transparent group-data-[checked]:bg-teal-600 group-data-[focus]:ring-2 group-data-[focus]:ring-teal-500 group-data-[focus]:ring-offset-2"
                          >
                            <span className="h-1.5 w-1.5 rounded-full bg-white" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <span className="block text-sm font-medium text-gray-900 group-data-[checked]:text-teal-900">
                              {setting.name}
                            </span>
                            <span className="block text-sm text-gray-500 group-data-[checked]:text-teal-700">
                              {setting.description}
                            </span>
                          </span>
                        </Radio>
                      ))}
                    </RadioGroup>
                  </div>
                </div>
              </Field>

              <Field as="li" className="flex items-center justify-between py-4">
                <div className="flex flex-col">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Add your Dataset
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Connect an existing or new dataset to your model
                  </Description>
                </div>
                <div className="flex justify-right" style={{ width: "65%" }}>
                  <div style={{ width: "30%" }}></div>
                  <div style={{ width: "40%" }}>
                    <Listbox
                      value={selectedDataset}
                      onChange={setSelectedDataset}
                    >
                      <div className="relative">
                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {selectedDataset.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              aria-hidden="true"
                              className="h-5 w-5 text-gray-400"
                            />
                          </span>
                        </ListboxButton>

                        <ListboxOptions
                          transition
                          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                          {people.map((person) => (
                            <ListboxOption
                              key={person.id}
                              value={person}
                              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                            >
                              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                {person.name}
                              </span>

                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                  aria-hidden="true"
                                  className="h-5 w-5"
                                />
                              </span>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </div>
                    </Listbox>
                  </div>
                  <div style={{ width: "30%" }}>
                    <span className="ml-3 ">
                      <button
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <PlusIcon
                          aria-hidden="true"
                          className="-ml-0.5 h-5 w-5 text-gray-400"
                        />
                        New Dataset
                      </button>
                    </span>
                  </div>

                  <span className="ml-5">
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <ArrowPathIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                      />
                    </button>
                  </span>
                </div>
              </Field>

              <Field as="li" className="flex items-center justify-between py-4">
                <div className="flex flex-col">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Decompose Inputs
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Enable or disable input decomposition for the model.
                  </Description>
                </div>
                <Switch
                  checked={formData.emulator_params.decompose_inputs}
                  onChange={(checked) =>
                    handleSwitchChange(
                      checked,
                      "emulator_params",
                      "decompose_inputs"
                    )
                  }
                  className="group relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 data-[checked]:bg-teal-500"
                >
                  <span
                    aria-hidden="true"
                    className="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                  />
                </Switch>
              </Field>

              <Field as="li" className="flex items-center justify-between py-4">
                <div className="flex flex-col">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Decompose Outputs
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Enable or disable output decomposition for the model.
                  </Description>
                </div>
                <Switch
                  checked={formData.emulator_params.decompose_outputs}
                  onChange={(checked) =>
                    handleSwitchChange(
                      checked,
                      "emulator_params",
                      "decompose_outputs"
                    )
                  }
                  className="group relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 data-[checked]:bg-teal-500"
                >
                  <span
                    aria-hidden="true"
                    className="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                  />
                </Switch>
              </Field>

              <Field as="li" className="flex items-center justify-between py-4">
                <div className="flex flex-col">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Model Selection
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Let AutoML pick your Machine Learning Kernel
                  </Description>
                </div>
                <Switch
                  checked={formData.training_params.model_selection}
                  onChange={(checked) =>
                    handleSwitchChange(
                      checked,
                      "training_params",
                      "model_selection"
                    )
                  }
                  className="group relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 data-[checked]:bg-teal-500"
                >
                  <span
                    aria-hidden="true"
                    className="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                  />
                </Switch>
              </Field>

              <Field as="li" className="flex items-center justify-between py-4">
                <div className="flex flex-col">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Test / Train Split
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Choose the percentage of data used for training, and that
                    reserved for testing.
                  </Description>
                </div>

                <div className="relative flex items-center max-w-[8rem]">
                  <button
                    type="button"
                    id="decrement-button"
                    data-input-counter-decrement="quantity-input"
                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  >
                    <svg
                      className="w-3 h-3 text-gray-900 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 2"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 1h16"
                      />
                    </svg>
                  </button>
                  <input
                    type="text"
                    id="quantity-input"
                    data-input-counter
                    aria-describedby="helper-text-explanation"
                    className="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={formData.training_params.train_test_ratio}
                    min={0.0}
                    max={1.0}
                    step={0.01}
                    required
                  />
                  <button
                    type="button"
                    id="increment-button"
                    data-input-counter-increment="quantity-input"
                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  >
                    <svg
                      className="w-3 h-3 text-gray-900 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 1v16M1 9h16"
                      />
                    </svg>
                  </button>
                </div>
              </Field>

              <Field as="li" className="flex items-center justify-between py-4">
                <div className="flex flex-col">
                  <Label
                    as="p"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Shuffle Training Data
                  </Label>
                  <Description className="text-sm text-gray-500">
                    Select if you would like your training & test data to be
                    shuffled before training.
                  </Description>
                </div>
                <Switch
                  checked={formData.training_params.shuffle}
                  onChange={(checked) =>
                    handleSwitchChange(checked, "training_params", "shuffle")
                  }
                  className="group relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 data-[checked]:bg-teal-500"
                >
                  <span
                    aria-hidden="true"
                    className="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                  />
                </Switch>
              </Field>
            </ul>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end gap-x-3">
            <button
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
            >
              Save Model
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
