export default {
  data: {
    unitWidth: "200px",
    menuWidth: "200px",
    leftColumnPercentage: 40,
    headerColor: "bg-yellow-200",
    title: "Float Input",
    type: "var",
    subclass: "Variable",
    shortdescription: `
      This node allows the user to input a float.
          `,
    description: "This node allows the user to input a float.",
    formType: "float",
    label: "Float",
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Float"],
    targetTypes: [],
    sourceTypes: ["number"],
    params: [
      {
        id: "float_input",
        label: "Float",
        formType: "float",
        value: null,
      },
    ],
    action: "floatInput",
  },
};
