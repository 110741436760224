export default {
  data: {
    unitWidth: "200px",
    menuWidth: "200px",
    leftColumnPercentage: 40,
    headerColor: "bg-yellow-200",
    title: "Integer Input",
    type: "var",
    subclass: "Variable",
    shortdescription: `
      This node allows the user to input a integer.
          `,
    description: "This node allows the user to input a integer.",
    formType: "integer",
    label: "Integer",
    numTargets: 0,
    numSources: 1,
    targetLabels: [],
    sourceLabels: ["Integer"],
    targetTypes: [],
    sourceTypes: ["number"],
    params: [
      {
        id: "integer_input",
        label: "Integer",
        formType: "integer",
        value: null,
      },
    ],
    action: "integerInput",
  },
};
