// App.js
import { useState, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ReactFlowProvider } from "@xyflow/react";

import WorkflowCanvas from "./pages/WorkflowCanvas";
import AppCanvas from "./pages/AppCanvas";
import SettingsPage from "./pages/SettingsPage";
import LoginPage from "./pages/LoginPage";

import ModelBuilder from "./pages/ModelBuilderTest";

import useTabs from "./hooks/useTabs";
import { getTabType } from "./utils/DBUtils";
import { InteractionProvider } from "./contexts/InteractionProvider";

function App() {
  const [currentUser, setCurrentUser] = useState("u3"); // Set the current user
  const tabsData = useTabs();
  const [activeModal, setActiveModal] = useState(null); // Manages which modal is open

  // Determine the component to render based on the tab type
  const renderCanvas = () => {
    const tabType = getTabType(tabsData.currentTabId);
    if (tabType === "workflow") {
      return (
        <ReactFlowProvider>
          <InteractionProvider>
            <WorkflowCanvas
              currentUser={currentUser}
              useTabs={tabsData}
              setActiveModal={setActiveModal}
              activeModal={activeModal}
            />
          </InteractionProvider>
        </ReactFlowProvider>
      );
    } else if (tabType === "model") {
      return <ModelBuilder currentUser={currentUser} useTabs={tabsData} />;
    } else if (tabType === "app") {
      return (
        <AppCanvas
          currentUser={currentUser}
          useTabs={tabsData}
          setActiveModal={setActiveModal}
          activeModal={activeModal}
        />
      );
    }
    return "Something went wrong!";
  };

  return (
    <Router>
      {/* Main content area where the different pages will render */}
      <div>
        <Routes>
          {/* The default route will load WorkflowCanvas */}
          <Route path="/" element={renderCanvas()} />

          {/* Route to settings page */}
          <Route
            path="/settings"
            element={<SettingsPage currentUser={currentUser} />}
          />

          {/* Route to login page */}
          <Route path="/login" element={<LoginPage />} />

          {/* Route to Coming Soon page */}
          <Route
            path="/coming-soon"
            element={<h2 className="text-4xl font-bold">Coming soon...</h2>}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
