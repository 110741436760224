import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  getWorkflows,
  getWorkflow,
  getModels,
  getModel,
  getApps,
  getApp,
} from "../../../utils/DBUtils";
import { loadingIcon } from "../../../assets/icons/customIcons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function LoadCanvasModal({
  open,
  setOpen,
  initialCategory,
  currentUser,
  useTabs,
}) {
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { addTab, tabs, selectTab } = useTabs;
  const [loading, setLoading] = useState(false);

  // Files to display on pop up
  const models = getModels(currentUser)
    .map((model) => model.id)
    .filter((id) => !tabs.includes(id) && id !== "m0");
  const workflows = getWorkflows(currentUser)
    .map((workflow) => workflow.id)
    .filter((id) => !tabs.includes(id) && id !== "w0");
  const apps = getApps(currentUser)
    .map((app) => app.id)
    .filter((id) => !tabs.includes(id) && id !== "a0");

  const handleDone = async () => {
    if (selectedCategory === "workflows") {
      setLoading(true);
      addTab(selectedItem);
      selectTab(tabs.length);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
      setOpen(false);
    } else {
      alert("Load the selected item");
      setOpen(false);
    }
  };

  // Categories to load
  const renderOptions = () => {
    const options = {
      models,
      workflows,
      apps,
    }[selectedCategory];
    const filteredOptions = options.filter((item) =>
      item.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredOptions.map((item) => (
      <li key={item}>
        <button
          className={classNames(
            "block w-full text-left px-4 py-2 text-sm rounded-md",
            selectedItem === item
              ? "bg-indigo-100"
              : "bg-white hover:bg-gray-100"
          )}
          onClick={() => setSelectedItem(item)}
        >
          {selectedCategory === "workflows"
            ? getWorkflow(item).name
            : selectedCategory === "models"
              ? getModel(item).name
              : selectedCategory === "apps"
                ? getApp(item).name
                : item}
        </button>
      </li>
    ));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-hidden">
          {loading ? (
            <div className="flex items-center justify-center h-full w-full">
              <div className="flex h-16">{loadingIcon}</div>
            </div>
          ) : (
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full h-[25%]">
                <>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-semibold leading-6 text-gray-900"
                        >
                          What do you want to load?
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  {/* Search bar */}
                  <div className="mt-2 mx-10">
                    <input
                      type="text"
                      placeholder="Search files..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full px-3 border border-gray-300 rounded-md"
                    />
                  </div>

                  {/* Horizontal selection bar */}
                  <div className="mt-4 mx-10 w-full">
                    <div className="flex justify-between mr-20">
                      <button
                        className={classNames(
                          "flex-grow px-4 py-2 text-sm text-center font-medium rounded-t-md",
                          selectedCategory === "models"
                            ? "bg-indigo-600 text-white border border-indigo-600"
                            : "bg-gray-200 text-gray-700 border border-gray-300"
                        )}
                        onClick={() => setSelectedCategory("models")}
                      >
                        Models
                      </button>
                      <button
                        className={classNames(
                          "flex-grow px-4 py-2 text-sm text-center font-medium rounded-t-md",
                          selectedCategory === "workflows"
                            ? "bg-indigo-600 text-white border border-indigo-600"
                            : "bg-gray-200 text-gray-700 border border-gray-300"
                        )}
                        onClick={() => setSelectedCategory("workflows")}
                      >
                        Workflows
                      </button>
                      <button
                        className={classNames(
                          "flex-grow px-4 py-2 text-sm text-center font-medium rounded-t-md",
                          selectedCategory === "apps"
                            ? "bg-indigo-600 text-white border border-indigo-600"
                            : "bg-gray-200 text-gray-700 border border-gray-300"
                        )}
                        onClick={() => setSelectedCategory("apps")}
                      >
                        Apps
                      </button>
                    </div>
                  </div>

                  {/* Display options based on selected category */}
                  <div className=" mx-10 border border-indigo-600 p-2 max-h-48 overflow-y-auto rounded-b-md shadow-xl h-[30vh]">
                    <ul className="space-y-2 divide-y">{renderOptions()}</ul>
                  </div>

                  {/* Create buttons for Load and Cancel at the bottom of pop-up */}
                  <div className="mt-4 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleDone}
                    >
                      Load
                    </button>
                    <button
                      type="button"
                      className="mt-4 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              </Dialog.Panel>
            </div>
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default LoadCanvasModal;
