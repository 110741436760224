import React, { memo, useState, useCallback, useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import generateHandles from "./node-utils/generateHandles";
import DynamicList from "./cards/node-components/DynamicList";
import GeneralNode from "./cards/GeneralNode";
import VariableNode from "./cards/VariableNode";

const statusColorMap = {
  green: "bg-green-400",
  amber: "bg-yellow-400",
  red: "bg-red-400",
};

function BaseNode({ data }) {
  const {
    unitWidth,
    id,
    numTargets = 0,
    numSources = 0,
    targetTypes = [],
    sourceTypes = [],
    dynamicList = false,
    listExtraInputs = [],
  } = data;

  const headerHeight = 60;
  const {
    handles: staticHandles,
    offsetTarget,
    offsetSource,
  } = generateHandles(
    numTargets,
    numSources,
    targetTypes,
    sourceTypes,
    headerHeight
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [extraInputs, setExtraInputs] = useState(listExtraInputs);
  const [dynamicHandles, setDynamicHandles] = useState([]);
  const updateNodeInternals = useUpdateNodeInternals();

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  // Function to add a dynamic handle
  const addDynamicHandle = useCallback(() => {
    const newHandle = {
      id: `dynamic_${extraInputs.length + 1}`,
      type: "target",
      position: Position.Left,
      dataType: "str",
    };
    setDynamicHandles((prevHandles) => [...prevHandles, newHandle]);
  }, [extraInputs.length]);

  const deleteDynamicHandle = (handleId) => {
    setDynamicHandles(
      (dynamicHandles) =>
        dynamicHandles.filter((handle) => handle.id !== handleId),
      console.log("deleteDynamicHandle", dynamicHandles)
    );
  };

  // Update node internals when dynamic handles change
  useEffect(() => {
    updateNodeInternals(id);
  }, [id, dynamicHandles, updateNodeInternals]);

  return (
    <div>
      {data.type === "var" ? (
        <VariableNode data={data} />
      ) : (
        <div className="rounded-lg border border-gray-400">
          <GeneralNode
            data={data}
            statusColorMap={statusColorMap}
            offsetTarget={offsetTarget}
            offsetSource={offsetSource}
            toggleExpansion={toggleExpansion}
            isExpanded={isExpanded}
          />

          {dynamicList && (
            <div
              className="flex flex-col divide-y divide-gray-100 px-2 text-sm text-gray- leading-6 bg-gray-50 rounded-b-lg border-gray-400"
              style={{ width: unitWidth }}
            >
              <DynamicList
                headerHeight={headerHeight}
                extraInputs={extraInputs}
                setExtraInputs={setExtraInputs}
                addDynamicHandle={addDynamicHandle} // Call this when extraInputs changes
                deleteDynamicHandle={deleteDynamicHandle}
              />
            </div>
          )}
          {/* Render Static Handles */}
          {staticHandles.map((handleConfig) => (
            <Handle
              key={handleConfig.id}
              id={handleConfig.id}
              type={handleConfig.type}
              position={handleConfig.position}
              className="rounded-full w-2 h-2 !bg-black ring-2 ring-gray-00 ring-offset-2 ring-offset-gray-50"
              style={handleConfig.style}
            />
          ))}
          {/* Render Dynamic Handles */}
          {dynamicHandles.map((handle, i) => (
            <Handle
              key={handle.id}
              id={handle.id}
              type={handle.type}
              position={handle.position}
              className="rounded-full w-2 h-2 !bg-black ring-2 ring-gray-00 ring-offset-2 ring-offset-gray-50"
              style={{
                top: 340 + 58 * (i - 1),
                position: "absolute",
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(BaseNode);
