export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-green-200",
    title: "Multivariant Gaussian sampler",
    subclass: "Stastical Method",
    action: "multivariantGaussian",
    type: "math",
    shortdescription: `
    Sample inputs from a multivariant Gaussian distribution.
        `,
    description: `
Here I should put a description about what a Mulitvariant Sampler is!
    `,
    numTargets: 4,
    numSources: 1,
    targetLabels: ["Dimension", "Mean", "Covariance", "Number of Samples"],
    sourceLabels: ["Samples"],
    targetTypes: ["int", "vec", "matrix", "int"],
    sourceTypes: ["samples"],
    params: {},
  },
};
