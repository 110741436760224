import React from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { headerNavIcons } from "../../assets/icons/customIcons";
import {
  getAccountImage,
  getAccountName,
  getAccountEmail,
  getTabName,
  getTabType,
} from "../../utils/DBUtils";

const userNavigation = [
  { name: "Your Profile", href: "coming-soon" },
  { name: "Settings", href: "settings" },
  { name: "Sign out", href: "login" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({
  setActiveModal,
  currentUser,
  tabs,
  currentTabId,
  selectTab,
}) => {
  function getTabIcon(tabId) {
    let tabType = getTabType(tabId);
    let icon;
    switch (tabType) {
      case "workflow":
        icon = headerNavIcons.workflow;
        break;
      case "model":
        icon = headerNavIcons.model;
        break;
      case "app":
        icon = headerNavIcons.app;
        break;
      default:
        throw new Error(`Invalid tab type.`);
    }
    if (!tabType) {
      throw new Error(`Icon for tab type ${tabType} not found`);
    }
    return icon;
  }

  const navigation = [
    {
      name: "Home",
      href: "coming-soon",
      current: false,
      icon: headerNavIcons.home,
    },
    {
      name: "Community",
      href: "coming-soon",
      current: false,
      icon: headerNavIcons.community,
    },
    ...tabs.map((tab, index) => {
      return {
        name: getTabName(tab),
        index: index,
        href: "#",
        current: tab === currentTabId, // Assuming the first tab is the current one
        isWorksheet: true, // Set isWorksheet to true only if tabType is not "app"
        icon: getTabIcon(tab),
        closeIcon: headerNavIcons.close,
      };
    }),
  ];

  // Conditionally add the Add button if currentTabId is not "settings"
  if (currentTabId !== "settings") {
    navigation.push({
      name: "Add",
      href: "#",
      current: false,
      icon: headerNavIcons.add,
      isAddButton: true, // Indicate that this is the Add button
    });
  }

  return (
    <Disclosure
      as="nav"
      className="border-b border-gray-200 bg-white fixed w-full top-0 z-50"
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          {/* Navigation on the left */}
          <div className="flex">
            {/* Custom Text Logo "tL" */}
            <button
              className="flex flex-shrink-0 items-center"
              onClick={() =>
                (window.location.href = "https://www.digilab.co.uk/twinlab")
              }
            >
              <span className="text-blue-900 text-2xl font-bold">tL</span>{" "}
              {/* Dark blue large font "tL" */}
            </button>

            {/* Navigation Links */}
            <div className="hidden sm:-my-px sm:ml-6 sm:flex">
              {navigation.slice(0, 2).map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  aria-current={item.current ? "page" : undefined}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
                    "inline-flex items-center justify-center h-full px-3 pt-1 text-sm font-medium"
                  )}
                >
                  {item.icon}
                </a>
              ))}

              {/* Worksheet Tabs */}
              {navigation.slice(2).map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  aria-current={item.current ? "page" : undefined}
                  onClick={() => {
                    if (item.isAddButton) {
                      // Open the NewCanvasModal on Add button click
                      setActiveModal("NewCanvasModal");
                    } else if (item.isWorksheet) {
                      selectTab(item.index);
                    }
                  }}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-500 hover:bg-gray-100 hover:text-gray-700",
                    "inline-flex items-center justify-center h-full px-3 pt-1 text-sm font-medium",
                    item.isWorksheet || item.isAddButton
                      ? "py-2 px-2 rounded-t-lg mt-1"
                      : ""
                  )}
                  style={{ marginRight: "1px" }} // Custom margin between tabs
                >
                  {item.icon}
                  {item.isWorksheet && (
                    <span className="ml-2">{item.name}</span>
                  )}
                  {item.isWorksheet && item.closeIcon}
                </a>
              ))}
            </div>
          </div>

          {/* User Navigation on the right */}
          <div className="flex items-center mr-6">
            <button
              type="button"
              className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              <BellIcon aria-hidden="true" className="h-6 w-6" />
            </button>

            <Menu as="div" className="relative ml-3">
              <div>
                <MenuButton className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  <img
                    alt=""
                    src={getAccountImage(currentUser)}
                    className="h-8 w-8 rounded-full"
                  />
                </MenuButton>
              </div>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {userNavigation.map((item) => (
                  <MenuItem key={item.name}>
                    <a
                      href={item.href}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                    >
                      {item.name}
                    </a>
                  </MenuItem>
                ))}
              </MenuItems>
            </Menu>
          </div>

          {/* Mobile menu button */}
          <div className="-mr-2 flex items-center sm:hidden">
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block h-6 w-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden h-6 w-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden z-50">
        {" "}
        {/* Ensure z-index is higher */}
        <div className="space-y-1 pb-3 pt-2 bg-white shadow-md">
          {" "}
          {/* Add shadow and background for better visibility */}
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              aria-current={item.current ? "page" : undefined}
              className={classNames(
                item.current
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-600 hover:bg-gray-100 hover:text-gray-800",
                "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
              )}
            >
              {item.icon}
              {item.isWorksheet && <span className="ml-2">{item.name}</span>}
              {item.isWorksheet && item.closeIcon}
            </DisclosureButton>
          ))}
        </div>
        <div className="border-t border-gray-200 pb-3 pt-4">
          <div className="flex items-center px-4">
            <div className="flex-shrink-0">
              <img
                alt=""
                src={getAccountImage(currentUser)}
                className="h-10 w-10 rounded-full"
              />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">
                {getAccountName(currentUser)}
              </div>
              <div className="text-sm font-medium text-gray-500">
                {getAccountEmail(currentUser)}
              </div>
            </div>
            <button
              type="button"
              className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              <BellIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-3 space-y-1">
            {userNavigation.map((item) => (
              <DisclosureButton
                key={item.name}
                as="a"
                href={item.href}
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
              >
                {item.name}
              </DisclosureButton>
            ))}
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};

export default Header;
