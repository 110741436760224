import { useState, useMemo } from "react";

function useTabs(initialTabs = ["w1", "m1", "a1"]) {
  const [tabs, setTabs] = useState(initialTabs);
  const [currentTab, setCurrentTab] = useState(0);

  const addTab = (tab: string) => setTabs([...tabs, tab]);
  const removeTab = (index: number) => {
    setTabs(tabs.filter((_, i) => i !== index));
    if (currentTab >= index && currentTab > 0) {
      setCurrentTab(currentTab - 1);
    }
  };
  const selectTab = (index: number) => setCurrentTab(index);

  // Derive currentTabId from currentTab and tabs
  const currentTabId = useMemo(() => tabs[currentTab], [tabs, currentTab]);

  return {
    tabs,
    currentTab,
    currentTabId,
    addTab,
    removeTab,
    selectTab,
  };
}

export default useTabs;
