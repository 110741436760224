import React, { useEffect } from "react";
import { HomeIcon } from "@heroicons/react/20/solid";
import { StarIcon } from "@heroicons/react/24/solid";
import { StarIcon as StarOutlineIcon } from "@heroicons/react/24/outline";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Label,
} from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/20/solid"; // Import PencilIcon
import { getProjectNamefromWorkflow, getTabName } from "../../utils/DBUtils";

const options = [
  {
    title: "Published",
    description:
      "Workflow will be used with agentic systems across the project.",
    buttonColor: "bg-green-400",
    hoverColor: "hover:bg-green-200",
    selectedColor: "text-green-900",
    icon: <CheckIcon aria-hidden="true" className="h-5 w-5 text-green-800" />, // Tick for published
    isDraft: false,
  },
  {
    title: "Draft",
    description: "Workflow is under construction, and won't be used by others.",
    buttonColor: "bg-yellow-400",
    hoverColor: "hover:bg-yellow-200",
    selectedColor: "text-yellow-800",
    icon: <PencilIcon aria-hidden="true" className="h-5 w-5 text-yellow-800" />, // Pencil for draft
    isDraft: true,
  },
];

export default function Breadcrumb({
  currentProject,
  currentTabId,
  isFavorite: propIsFavorite,
  isDraft: propIsDraft,
  setIsFavorite,
  setIsDraft,
}) {
  const [isFavorite, setIsFavoriteInternal] = React.useState(propIsFavorite);
  const [isDraft, setIsDraftInternal] = React.useState(propIsDraft);

  // Update internal state when props change
  useEffect(() => {
    setIsFavoriteInternal(propIsFavorite);
  }, [propIsFavorite]);

  useEffect(() => {
    setIsDraftInternal(propIsDraft);
  }, [propIsDraft]);

  // Find the selected option to apply its styles based on isDraft
  const selectedOption =
    options.find((option) => option.isDraft === isDraft) || options[0];

  return (
    <div className="p-2 bg-white border border-gray-400 rounded-md h-14">
      <nav aria-label="Breadcrumb" className="flex items-center">
        <ol
          role="list"
          className="flex space-x-2 rounded-md px-4 py-0.5 text-sm"
        >
          <li className="flex">
            <div className="flex items-center">
              <a href="#" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  aria-hidden="true"
                  className="mr-2 h-6 w-6 flex-shrink-0"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>

          <li className="flex">
            <div className="flex items-center">
              <svg
                fill="currentColor"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                aria-hidden="true"
                className="h-full w-4 flex-shrink-0 text-gray-800"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ml-2 text-base font-medium text-gray-500 hover:text-gray-700">
                {getProjectNamefromWorkflow(currentTabId)}
              </p>
            </div>
          </li>

          <li className="flex">
            <div className="flex items-center">
              <svg
                fill="currentColor"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                aria-hidden="true"
                className="h-full w-4 flex-shrink-0 text-gray-800"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ml-4 text-base font-medium text-gray-500 hover:text-gray-700">
                {getTabName(currentTabId)}
              </p>
            </div>
          </li>
        </ol>

        {/* Favorite Star */}
        <button
          onClick={() => {
            setIsFavoriteInternal(!isFavorite);
            if (setIsFavorite) setIsFavorite(!isFavorite);
          }}
          className="ml-4 focus:outline-none"
        >
          {isFavorite ? (
            <StarIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
          ) : (
            <StarOutlineIcon
              className="h-6 w-6 text-gray-400 hover:text-gray-500"
              aria-hidden="true"
            />
          )}
          <span className="sr-only">Mark as favorite</span>
        </button>

        {/* Dropdown Menu for Workflow Status */}
        <div className="px-4">
          <Listbox
            value={isDraft}
            onChange={(value) => {
              setIsDraftInternal(value);
              if (setIsDraft) setIsDraft(value);
            }}
          >
            <Label className="sr-only">Change status</Label>
            <div className="relative">
              <div className="inline-flex w-38 rounded-md shadow-sm">
                <div
                  className={`inline-flex items-center gap-x-1.5 rounded-l-md px-2 py-2 shadow-sm ${selectedOption.buttonColor} w-full ${selectedOption.selectedColor}`}
                >
                  {selectedOption.icon} {/* Display the selected icon */}
                  <p className="text-sm font-semibold truncate">
                    {selectedOption.title}
                  </p>
                </div>
                <ListboxButton
                  className={`inline-flex items-center rounded-l-none rounded-r-md p-2 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 ${selectedOption.buttonColor}`}
                >
                  <span className="sr-only">Change status</span>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className={`h-5 w-5 ${selectedOption.selectedColor}`} // Apply selected color to the arrow
                  />
                </ListboxButton>
              </div>

              <ListboxOptions
                transition
                className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {options.map((option) => (
                  <ListboxOption
                    key={option.title}
                    value={option.isDraft}
                    className={`group cursor-default select-none p-4 text-sm ${option.hoverColor} hover:text-black`}
                  >
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <p className={`font-semibold text-black`}>
                          {option.title}
                        </p>
                        <span
                          className={`group-data-[focus]:text-black [.group:not([data-selected])_&]:hidden`}
                        >
                          {option.icon}
                        </span>
                      </div>
                      <p className="mt-2 text-gray-500">{option.description}</p>
                    </div>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
      </nav>
    </div>
  );
}
