import React from "react";
import {
  ChatBubbleLeftEllipsisIcon,
  CogIcon,
  SparklesIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

// Array of conversations
const conversations = [
  {
    id: 1,
    title: "Plotly Technology Overview",
    created: "2024-09-26T12:30:00Z",
    lastUpdated: "2024-09-26T15:00:00Z",
    owner: "Tim Dodwell",
    isCurrent: true,
  },
  {
    id: 2,
    title: "Dynamically Adding Node Handles",
    created: "2024-09-25T09:00:00Z",
    lastUpdated: "2024-09-25T12:00:00Z",
    owner: "Tim Dodwell",
    isCurrent: false,
  },
  {
    id: 3,
    title: "Using Open Source LLMs",
    created: "2024-09-25T10:00:00Z",
    lastUpdated: "2024-09-25T13:30:00Z",
    owner: "Jane Doe",
    isCurrent: false,
  },
  {
    id: 4,
    title: "Modal Opening Icons",
    created: "2024-09-25T11:00:00Z",
    lastUpdated: "2024-09-25T13:45:00Z",
    owner: "John Smith",
    isCurrent: false,
  },
  // Add more conversations as needed
];

const ChatSidebar = () => {
  return (
    <div className="h-screen bg-gray-100 flex flex-col pt-16">
      {/* Section: Button to Start New Conversation */}
      <div className="p-4">
        <button className="w-full bg-gray-200 hover:bg-gray-300 text-center py-2 rounded-lg font-medium text-sm text-gray-700">
          Start New Conversation
        </button>
      </div>

      {/* Conversations List */}
      <div className="flex-1 px-4 py-2  overflow-y-auto rounded-lg border-t border-gray-300">
        <h4 className="text-s font-semibold py-2 text-gray-500">
          Previous Conversations
        </h4>

        <div className="space-y-4">
          {/* Display Conversations Dynamically */}
          {conversations.map((conversation) => (
            <div
              key={conversation.id}
              className={`${
                conversation.isCurrent
                  ? "bg-gray-300 border-l-4 border-gray-500"
                  : "bg-gray-200"
              } rounded-lg p-2 mt-2 cursor-pointer hover:bg-gray-300`}
            >
              <span className="text-sm font-medium">{conversation.title}</span>
              <p className="text-xs text-gray-500">
                Last updated:{" "}
                {new Date(conversation.lastUpdated).toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <div className="p-4 border-t border-gray-300">
        <button className="w-full bg-gray-200 text-center py-2 rounded-lg font-medium text-sm text-gray-700">
          Share
        </button>
      </div>
    </div>
  );
};

export default ChatSidebar;
