export default {
  data: {
    unitWidth: "350px",
    headerColor: "bg-pink-200",
    title: "Line plot",
    subclass: "Export / Visualization",
    type: "plot",
    action: "plotline1D",
    shortdescription: `
    Generate a line plot, as an output.
        `,
    description: `
This function makes a line plot from data.
    `,
    numTargets: 2,
    numSources: 0,
    targetLabels: ["X-Axis", "Y-Axis"],
    sourceLabels: [],
    targetTypes: ["samples", "samples"],
    sourceTypes: [],
    params: {
      title: "Plot",
      xaxisLabel: "X-Axis",
      yaxisLabel: "Y-Axis",
    },
  },
};
